import React from 'react'

import Layout from '../components/layout'
import ButtonLink from '../components/ButtonLink'
import { H1 } from '../components/Headlines'
import { Hero } from '../components/Hero'
import Section from '../components/Section'
import { GallerySection } from '../components/GallerySection'
import { TestDrive } from '../components/TestDrive'
import SimpleSlider from '../components/Slider'

import { ImageBox } from '../components/ImageBox'

import NeverStopImproving from '../images/c-class-t-modell/highlights/never-stop-improving.jpeg'
import SportyDesign from '../images/c-class-t-modell/highlights/sporty-design.jpeg'
import DigitalCockpit from '../images/c-class-t-modell/highlights/digital-cockpit.jpeg'
import IntelligentDrive from '../images/c-class-t-modell/highlights/intelligent-drive.jpeg'
import Energizing from '../images/c-class-t-modell/highlights/energizing.jpeg'
import Variability from '../images/c-class-t-modell/highlights/variability.jpeg'

import HeaderImage from '../images/c-class-t-modell/header-c-class-t-modell.jpeg'

import InterieurImage from '../images/c-class-t-modell/gallery/interieur-teaser-c-class-t-modell.jpeg'
import ExterieurImage from '../images/c-class-t-modell/gallery/exterieur-teaser-c-class-t-modell.jpeg'

import InterieurImageGallery1 from '../images/c-class-t-modell/gallery/gallery1-interieur-c-class-t-modell.jpeg'
import InterieurImageGallery2 from '../images/c-class-t-modell/gallery/gallery2-interieur-c-class-t-modell.jpeg'
import InterieurImageGallery3 from '../images/c-class-t-modell/gallery/gallery3-interieur-c-class-t-modell.jpeg'
import InterieurImageGallery4 from '../images/c-class-t-modell/gallery/gallery4-interieur-c-class-t-modell.jpeg'

import ExterieurImageGallery1 from '../images/c-class-t-modell/gallery/gallery1-exterieur-c-class-t-modell.jpeg'
import ExterieurImageGallery2 from '../images/c-class-t-modell/gallery/gallery2-exterieur-c-class-t-modell.jpeg'
import ExterieurImageGallery3 from '../images/c-class-t-modell/gallery/gallery3-exterieur-c-class-t-modell.jpeg'

const GalleryImagesInterieurSrc = [
  InterieurImageGallery1,
  InterieurImageGallery2,
  InterieurImageGallery3,
  InterieurImageGallery4,
]

const GalleryImagesExterieurSrc = [
  ExterieurImageGallery1,
  ExterieurImageGallery2,
  ExterieurImageGallery3,
]
const CClassTModell = () => (
  <Layout>
    <Hero bgImage={HeaderImage} />
    <Section align="center">
      <H1>Never stop improving. Das neue C-Klasse T-Modell.</H1>
      <p>
        Der Bestseller geht in die nächste Runde: Umfangreich modifiziert,
        können die neue C-Klasse Limousine und das neue T-Modell ab sofort
        bestellt werden. Optisch lagen die Schwerpunkte der Überarbeitung auf
        der Fahrzeugfront sowie dem Design der Scheinwerfer und Rückleuchten.
        Ganz neu ist die elektronische Architektur. Der Kunde erlebt dies bei
        der User Experience mit einem auf Wunsch volldigitalen
        Instrumenten-Display und Multimediasystemen mit maßgeschneiderten
        Anzeige- und Informationsmöglichkeiten. Die Assistenzsysteme sind nun
        auf dem Stand der S-Klasse.
      </p>
      <ButtonLink to="/probefahrt/">weiter zur Terminauswahl</ButtonLink>
    </Section>
    <GallerySection
      InterieurImageSrc={InterieurImage}
      ExterieurImageSrc={ExterieurImage}
      GalleryImagesInterieur={GalleryImagesInterieurSrc}
      GalleryImagesExterieur={GalleryImagesExterieurSrc}
    />
    <Section align="default" variant="grey">
      <H1>
        Sich niemals auszuruhen, sondern Innovationskraft immer wieder neu zu
        beweisen.
      </H1>
      <SimpleSlider>
        <ImageBox
          imagesrc={NeverStopImproving}
          title="Never stop improving"
          headline="Never stop improving"
          description=""
        />
        <ImageBox
          imagesrc={SportyDesign}
          title="Sportliches Design"
          headline="Sportliche Eleganz im großen Stil."
          description="Vorwärtsdrang steht dem neuen C-Klasse T-Modell ins Gesicht geschrieben: Die Front strahlt Dynamik und Modernität aus. Die Stoßfänger sind neu gestaltet, je nach Ausstattung glänzen edle Chromelemente und faszinieren neue MULTIBEAM LED Scheinwerfer. Die elegante Dachlinie fällt sanft ab und lässt kaum erahnen, wie viel Raum und Variabilität sich unter ihr verbergen."
        />
        <ImageBox
          imagesrc={DigitalCockpit}
          title="Digitales Cockpit"
          headline="Neues digitales Cockpit."
          description="Das neue C-Klasse T-Modell bedienen Sie so intuitiv und einfach wie ein Smartphone. Das volldigitale Kombiinstrument ermöglicht eine ganz neue Visualisierung von Fahrdaten. Für Ihr persönliches Fahrerlebnis stehen drei aufwendig animierte Stile zur Wahl. In Kombination mit dem neuen 10,25 Zoll großen hochauflösenden Display des Multimediasystems, den neuen Touch Control Buttons am Lenkrad und der Sprachsteuerung LINGUATRONIC bedeutet das höchsten Bedienkomfort."
        />
        <ImageBox
          imagesrc={IntelligentDrive}
          title="Mercedes-Benz Intelligent Drive"
          headline="Neue Generation Mercedes-Benz Intelligent Drive."
          description="Schnell, präzise, wachsam: Hochmoderne Mercedes-Benz Intelligent Drive Assistenzsysteme entlasten wirkungsvoller denn je. Ob Wiederanfahren im Stau, situationsgerechte Bremskraftunterstützung bei erkannter Kollisionsgefahr oder Distanzeinhaltung auf der Autobahn bei bis zu 210 km/h: Dabei kann die neue C-Klasse den Fahrer schon heute unterstützen. Einen zuverlässigeren und aufmerksameren Partner können Sie sich unterwegs kaum wünschen."
        />
        <ImageBox
          imagesrc={Energizing}
          title="ENERGIZING Komfortsteuerung."
          headline="Kraft tanken mit der ENERGIZING Komfortsteuerung."
          description="Das neue C-Klasse T-Modell begeistert nicht nur mit seiner Kraft, es kann auch das Wohlbefinden des Fahrers fördern: mit der optionalen ENERGIZING Komfortsteuerung. Lichter, Klänge und Düfte können auf Tastendruck miteinander kombiniert werden, um die Insassen zu entspannen oder zu beleben. Die inkludierte Ambientebeleuchtung mit bis zu 64 Farben inszeniert den Innenraum im schönsten Licht."
        />
        <ImageBox
          imagesrc={Variability}
          title="Variabilität und Ladekomfort"
          headline="Für den ganz großen Sport: der Laderaum."
          description="Sperrige Gegenstände finden im Laderaum des neuen C-Klasse T-Modells fast so komfortabel Platz wie die Insassen. Er bietet ein Volumen von maximal 490 Litern, mit umgeklappter Rückbank sogar 1.510 Liter. Ein Komfortfeature, auf das Sie nicht mehr verzichten möchten: Die optionale EASY-PACK Heckklappe lässt sich elektrisch öffnen und schließen."
        />
      </SimpleSlider>
    </Section>
    <TestDrive />
  </Layout>
)

export default CClassTModell
